<template>
  <div class="down-progress" v-if="isDownload">
    <div class="down-content">
      <div class="title">Downloading...
          <div class="btn-close" @click="btnClose">×</div>
      </div>
      <el-progress :percentage="downProgress.process"></el-progress>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
name: "DownloadFile",
data() {
  return {
    downProgress: {
        process: 0
    },
    isDownload: false,
    cancel: null,
  }
},
methods: {
  btnClose() {
    this.cancel()
  },
  async downloadFile(url, fileName) {
    this.isDownload = true;
    let src1 = '';
    if (url.includes('https://resources.holowits.com.sg/')) {
      src1 = url.replace('https://resources.holowits.com.sg/', '/resource/');
    }
    if (url.includes('https://resources.holowits.com/')) {
      src1 = url.replace('https://resources.holowits.com/', '/resource/');
    }
    try {
      const {CancelToken} = axios;
      const res = await axios({
        method: 'get',
        url: src1,
        responseType: 'blob',
        onDownloadProgress: (evt) => {
          let process = (evt.loaded / evt.total * 100 | 0);
          this.downProgress.process = process;
        },
        cancelToken: new CancelToken((c) => {this.cancel = c})
      })
      let blob = new Blob([res.data]);
      const url1 = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('target', '_blank');
      a.setAttribute('download', `${fileName}`);
      a.href = url1;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url1);
      this.isDownload = false;
    } catch(e) {
      // console.log(e)
      this.isDownload = false;
      this.$message.error("Download failed");
    } finally {
      this.downProgress = {
          process: 0
      }
    }
  },
}
}
</script>

<style lang="scss" scoped>
.down-progress {
  width: 100vw;
  height: 100vh;
  background-color: #33333345;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 100;
  .down-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 400px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    .title {
      margin-bottom: 10px;
      color: #c7000b;
      position: relative;
      .btn-close {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        top: -5px;
        right: 10px;
        z-index: 102;
      }
    }
  }
  
}
</style>