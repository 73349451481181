<template>
  <div class="soft-download">
    <div class="banner">
        <h1>{{ name }}</h1>
    </div>
    <div class="soft-body">
        <table>
            <thead>
                <tr>
                    <th style="width: 60%;"><div>Software Name</div></th><th style="width: 10%;"><div>Size</div></th><th style="width: 15%;"><div>publication Date</div></th><th style="width: 15%; text-align: center;"><div>Download Software</div></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v, i)  in list" :key="i">
                    <td class="file_name">{{ v.softName }} &nbsp;<i v-show="v.isLock" class="el-icon-lock"></i> </td><td>{{ v.size }} Mb</td><td>{{ v.publicationDate | dateFilter }}</td><td class="t-btn"><a @click="download(v)" class="el-icon-download"></a></td>
                </tr>
            </tbody>
        </table>
    </div>
    <download-file ref="down"></download-file>
  </div>
</template>

<script>
import DownloadFile from '@/views/common/DownloadFile.vue';
import {MessageBox} from 'element-ui';
import {getSoftByLabId, getSoftsByInfo} from '@/api/softwareApi';
export default {
name: 'SoftDownload',
components: {
    DownloadFile
},
data() {
    return {
        name: '',
        id: '',
        list: [],
    }
},
async mounted() {
    this.id = this.$route.params.id;
    this.name = this.$store.state.software.softs[this.id];
    await this.getData();
},
methods: {
    async getData() {
        if (this.$store.state.client.user.account) {
            await this.getSoftsByInfo({
                email: this.$store.state.client.user.account,
                labId: this.$route.params.id
            })
        } else {
            await this.getSoftByLabId()
        }
    },
    async getSoftsByInfo(params) {
        const {data: {data, isSuccess}} = await getSoftsByInfo(params)
        if (isSuccess) {
            this.list = data
        }
    },
    async getSoftByLabId() {
        const {data: {data, isSuccess}} = await getSoftByLabId(this.id)
        if (isSuccess) {
            this.list = data
        }
    },
    toLogin() {
      this.$router.push({name: 'login'})
    },
    download(item) {
        if (!item.isLock) {
            // this.$refs.down.downloadFile(item.softSrc, item.softName)
            window.open(item.softSrc)
        } else {
            const h = this.$createElement;
            // 文件上锁了，就另外处理
            MessageBox.alert('',{
                title: 'tips',
                message: h('div', null, [
                    h('p', null, [
                        h('span', null, 'To download the requested file(s),  you need to log in and contact '),
                        h('i', { style: 'color: #d2333c' }, 'website@holowits.com '),
                        h('span', null, 'to request download permission. ')
                    ]),
                    h('p', null, 'Please provide your account and the full name of the requested file(s), so we can respond to your request. We will review your request as soon as possible and notify you by email whether your download permission has been approved.'),
                    h('p', null, 'Thank you for your interest in our content.')
                ]),
                confirmButtonText: 'Login',
                cancelButtonText: 'Cancel',
            }).then(action => {
                this.toLogin()
            })
        }
    }
},
filters: {
    dateFilter(value) {
      return value ? value.split(' ')[0] : ''
    }
}
}
</script>

<style lang="scss" scoped>
.el-icon-lock {
    font-weight: bold;
    color: #d2333c;
}
.soft-download {
    text-align: center;
    background-color: #f5f7fa;
    min-height: inherit;
    .banner {
        height: 320px;
        background-image: url('/static/img/banner.8e08f679.jpg');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.29);
            pointer-events: none;
        }
        h1 {
            text-align: left;
            width: 100%;
            padding-left: 200px;
            font-weight: 600;
            color: #ffffff;
            z-index: 2;
        }
    }
}
.soft-body {
    width: 80%;
    margin: auto;
}
table {
      width: 100%;
      margin-top: 8px;
      background-color: #ffffff;
      thead {
          height: 36px;
          background-color: #f0f0f0;
          th {
              margin: 0 10px;
              &:not(:first-child) {
                text-align: center;
              }
              &:not(:last-child) {
                div {
                  border-right: 1px solid rgba(128, 128, 128, 0.219);
                }  
              }
              div {
                padding: 0 10px;
              }
          }
      }
      tbody {
          .file_name {
            text-align: left;
          }
          tr {
              border-bottom: 1px solid rgba(128, 128, 128, 0.219);
              height: 36px;
              td {
                  padding: 0 10px;
                  &:not(:first-child) {
                    text-align: center;
                  }
              }
              .t-btn {
                text-align: center;
                a {
                  cursor: pointer;
                  color: #c7000b;
                }
                font-size: 20px;
              }
              .t-link {
                  border-bottom: 1px solid transparent;
                  cursor: pointer;
                  &:hover {
                      border-bottom: 1px solid #000;
                  }
              }
          }
      }
}
</style>